import { createRouter, createWebHistory } from 'vue-router';

// import Talking from '../views/Talking.vue';
import Notes from '../views/Notes.vue';  // 确保文件扩展名一致
import NoteDetail from '../views/NoteDetail.vue';  // 导入笔记详情组件
import Cards from '../views/Cards.vue';  // 确保文件扩展名一致
const routes = [
  { path: '/', redirect: '/notes' },
  // { path: '/talking', component: Talking },
  { path: '/notes', component: Notes },
  {
    path: '/note/:id',  // 动态路由，:id 是占位符
    component: NoteDetail, // 关联到笔记详情页面组件
    props: true,  // 允许路由参数作为组件的 prop
  },
  { path: '/cards', component: Cards },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
