<template>
  <div id="app" class="flex flex-col" :style="{ height: '100vh', height: 'calc(var(--vh, 1vh) * 100)' }">

    <!-- 登录注册卡片 -->
    <LoginRegisterCard v-if="!isLoggedIn" @loginSuccess="handleLoginSuccess" />

    <!-- 导航栏 -->
    <nav v-if="isLoggedIn" class="bg-gray-800 p-4 flex items-center justify-between h-14 relative z-50">
      <!-- 左侧：大屏显示的导航 -->
      <div class="hidden md:flex space-x-4">
        <template v-for="item in navItems" :key="item.name">
          <!-- 如果菜单项有子菜单 -->
          <div v-if="item.children" class="relative">
            <button @click="toggleSubMenu(item.name)" class="text-white hover:text-blue-500 focus:outline-none flex items-center">
              {{ item.name }}
              <!-- 箭头图标，展开时反转方向 -->
              <svg class="w-4 h-4 ml-1 transform transition-transform duration-200"
                   :class="{ 'rotate-180': expandedMenu === item.name }"
                   fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
              </svg>
            </button>
            <!-- 子菜单 -->
            <div v-if="expandedMenu === item.name" class="absolute left-0 mt-2 w-40 bg-gray-700 text-white rounded-md shadow-lg">
              <template v-for="subItem in item.children" :key="subItem.name">
                <router-link :to="subItem.to" class="block px-4 py-2 text-white hover:bg-gray-600"
                             @click="handleSubMenuClick(subItem.name)">
                  {{ subItem.name }}
                </router-link>
              </template>
            </div>
          </div>

          <!-- 没有子菜单的普通导航项 -->
          <router-link v-else :to="item.to" class="text-white hover:text-blue-500"
            :class="{ 'text-blue-500': currentTab === item.name }" @click="setTab(item.name)">
            {{ item.name }}
          </router-link>
        </template>
      </div>

      <!-- 左侧：小屏显示的汉堡菜单和标题 -->
      <div class="md:hidden flex items-center flex-grow">
        <button @click="toggleMenu" class="text-white focus:outline-none mr-4">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
        <div class="text-white text-lg">
          {{ currentTab }}
        </div>
      </div>

      <!-- 右侧：用户图标 -->
      <div class="flex items-center">
        <img src="https://via.placeholder.com/40" alt="User Avatar" @click="toggleUserMenu"
          class="w-8 h-8 rounded-full cursor-pointer">

        <!-- 用户菜单 -->
        <div v-if="isUserMenuOpen" class="absolute right-0 w-48 bg-white rounded-md shadow-lg z-20 top-14">
          <div class="px-4 py-2 border-b text-gray-800">{{ username }}</div>
          <button @click="logout" class="w-full text-left px-4 py-2 text-red-600 hover:bg-gray-100">
            退出登录
          </button>
        </div>
      </div>
    </nav>

    <!-- 小屏汉堡菜单展开时悬浮显示的导航 -->
    <div v-if="isMenuOpen" class="absolute top-14 left-0 w-full bg-gray-700 text-white space-y-2 md:hidden z-10">
      <template v-for="item in navItems" :key="item.name">
        <div v-if="item.children">
          <button @click="toggleSubMenu(item.name)" class="block px-4 py-2 text-left text-white w-full hover:bg-gray-600 flex items-center justify-between">
            {{ item.name }}
            <!-- 小屏上的箭头图标 -->
            <svg class="w-4 h-4 transform transition-transform duration-200"
                 :class="{ 'rotate-180': expandedMenu === item.name }"
                 fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
            </svg>
          </button>
          <div v-if="expandedMenu === item.name" class="pl-4">
            <template v-for="subItem in item.children" :key="subItem.name">
              <router-link :to="subItem.to" class="block px-4 py-2 text-white hover:bg-gray-600"
                           @click="handleSubMenuClick(subItem.name)">
                {{ subItem.name }}
              </router-link>
            </template>
          </div>
        </div>
        <router-link v-else :to="item.to" class="block px-4 py-2 text-white hover:bg-gray-600" @click="setTab(item.name)">
          {{ item.name }}
        </router-link>
      </template>
    </div>

    <!-- 路由视图 -->
    <router-view v-if="isLoggedIn" class="flex-grow overflow-auto"></router-view>

  </div>
</template>

<script>
import LoginRegisterCard from './components/LogSig.vue';

export default {
  name: 'mindbase',
  components: {
    LoginRegisterCard
  },
  data() {
    return {
      isMenuOpen: false,
      isUserMenuOpen: false,
      currentTab: '记录',
      isLoggedIn: false,
      username: '',
      // 导航项，某些菜单项有子菜单
      navItems: [
        // { name: '对话', to: '/talking' },
        { name: '记录', to: '/notes' },
        { name: '卡片', to: '/cards' },
      ],
      expandedMenu: null, // 存储当前展开的菜单
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    toggleUserMenu() {
      this.isUserMenuOpen = !this.isUserMenuOpen;
    },
    setTab(tab) {
      this.currentTab = tab;
      this.isMenuOpen = false;
    },
    handleLoginSuccess(username) {
      this.username = username;
      this.isLoggedIn = true;
    },
    logout() {
      this.username = '';
      this.isLoggedIn = false;
      localStorage.removeItem('token');
    },
    setVH() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    toggleSubMenu(itemName) {
      this.expandedMenu = this.expandedMenu === itemName ? null : itemName;
    },
    handleSubMenuClick(subItemName) {
      this.setTab(subItemName);
      this.expandedMenu = null;  // 隐藏子菜单
    }
  },
  mounted() {
    const token = localStorage.getItem('token');
    if (token) {
      // 假设这里有一个 API 请求来获取用户名
      this.username = 'User'; // 在实际应用中应通过 API 获取
      this.isLoggedIn = true;
    }

    // 设置 --vh 自定义属性的值
    this.setVH();
    // 当窗口大小改变时更新值
    window.addEventListener('resize', this.setVH);
  },
  beforeUnmount() {
    // 组件卸载时移除事件监听器
    window.removeEventListener('resize', this.setVH);
  }
};
</script>

<style scoped>
/* 样式可以根据需要自定义 */
</style>
