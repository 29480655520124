<template>
    <div class="p-4">
      <button @click="goBack" class="mb-4 p-2 bg-blue-500 text-white rounded">
        返回列表
      </button>
  
      <h2 class="text-2xl font-bold mb-4">笔记详情</h2>
  
      <div v-if="loading">加载中...</div>
      <div v-else-if="note">
        <div v-if="editing">
          <textarea
            v-model="editedContent"
            class="w-full p-2 border border-gray-300 rounded"
            rows="6"
          />
          <button
            @click="saveNote"
            class="mt-4 p-2 bg-green-500 text-white rounded"
            :disabled="loading"
          >
            保存
          </button>
          <button
            @click="cancelEdit"
            class="mt-4 ml-4 p-2 bg-gray-500 text-white rounded"
            :disabled="loading"
          >
            取消
          </button>
        </div>
        <div v-else>
          <p>{{ note.content }}</p>
          <p class="text-gray-600">{{ formatDate(note.created_at) }}</p>
          <button @click="editNote" class="mt-4 p-2 bg-blue-500 text-white rounded">
            编辑
          </button>
          <button
            @click="deleteNote"
            :disabled="deleting"
            class="mt-4 ml-4 p-2 bg-red-500 text-white rounded"
          >
            {{ deleting ? '删除中...' : '删除' }}
          </button>
        </div>
      </div>
      <p v-else>未找到笔记。</p>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { get, post } from '../utils';
  
  const router = useRouter();
  const route = useRoute();
  
  const note = ref(null);
  const loading = ref(false);
  const deleting = ref(false);
  const editing = ref(false);
  const editedContent = ref('');
  
  const id = route.params.id;
  
  // 加载笔记详情
  const fetchNote = async () => {
    loading.value = true;
    try {
      const token = localStorage.getItem('token');
      const data = await get(`/api/note/detail?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      note.value = data;
      editedContent.value = data.content;
    } catch (error) {
      console.error('Failed to fetch note:', error);
    } finally {
      loading.value = false;
    }
  };
  
  // 保存编辑的笔记
  const saveNote = async () => {
    loading.value = true;
    try {
      const token = localStorage.getItem('token');
      await post('/api/note/edit', { id, content: editedContent.value }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      note.value.content = editedContent.value;
      editing.value = false;
    } catch (error) {
      console.error('Failed to edit note:', error);
    } finally {
      loading.value = false;
    }
  };
  
  // 删除笔记
  const deleteNote = async () => {
    if (!confirm('确定要删除这条笔记吗？')) return;
  
    deleting.value = true;
    try {
      const token = localStorage.getItem('token');
      await post('/api/note/delete', { id }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      router.push('/');
    } catch (error) {
      console.error('Failed to delete note:', error);
    } finally {
      deleting.value = false;
    }
  };
  
  // 格式化日期
  const formatDate = (dateStr) => {
    return new Date(dateStr).toLocaleString();
  };
  
  // 返回到列表页面
  const goBack = () => {
    router.push('/');
  };
  
  // 进入编辑模式
  const editNote = () => {
    editing.value = true;
  };
  
  // 取消编辑
  const cancelEdit = () => {
    editing.value = false;
    editedContent.value = note.value.content;
  };
  
  // 加载笔记详情
  onMounted(() => {
    fetchNote();
  });
  </script>
  
  