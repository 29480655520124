import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // 确保引入了 router
import './assets/tailwind.css'

const app = createApp(App);

app.use(router); // 使用 router

app.mount('#app');
