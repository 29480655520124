<template>
  <!-- 外层容器，包含卡片列表和“添加卡片”按钮 -->
  <div class="board flex flex-wrap gap-4 p-4 overflow-auto max-h-screen">
    <!-- 使用 VueDraggableNext 包装卡片列表 -->
    <VueDraggableNext v-model="cards" @end="onCardDragEnd" item-key="id" class="flex flex-wrap gap-4"
      :handle="'.card-drag-handle'">
      <!-- 渲染卡片 -->
      <div v-for="card in cards" :key="card.id"
        class="card bg-gray-100 border border-gray-300 rounded-lg p-4 shadow flex flex-col w-80" :data-id="card.id">
        <div class="flex justify-between items-center mb-3">
          <!-- 卡片拖拽句柄 -->
          <span class="card-drag-handle cursor-move text-gray-500 mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
              <path d="M7 4h2v2H7V4zm0 4h2v2H7V8zm0 4h2v2H7v-2zm4-8h2v2h-2V4zm0 4h2v2h-2V8zm0 4h2v2h-2v-2z" />
            </svg>
          </span>
          <h3 class="text-lg font-semibold flex-1"> {{ card.title }}</h3>
          <!-- 卡片的省略号菜单 -->
          <div class="relative" @click.stop="toggleCardMenu(card.id)">
            <svg class="w-6 h-6 cursor-pointer text-gray-400" fill="currentColor" viewBox="0 0 20 20">
              <!-- 自定义垂直省略号 -->
              <circle cx="10" cy="5" r="1.5"></circle>
              <circle cx="10" cy="10" r="1.5"></circle>
              <circle cx="10" cy="15" r="1.5"></circle>
            </svg>
            <!-- 下拉菜单 -->
            <div v-if="activeCardMenu === card.id"
              class="absolute right-0 mt-2 w-32 bg-white border border-gray-200 rounded-md shadow-lg z-10">
              <button @click="openModal('editCard', card)"
                class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                编辑
              </button>
              <button @click="deleteCard(card.id)"
                class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                删除
              </button>
            </div>
          </div>
        </div>

        <!-- 使用 VueDraggableNext 包装列表项 -->
        <VueDraggableNext v-model="card.lists" @end="(event) => onListDragEnd(event, card)" item-key="id"
          class="mb-3 space-y-2" :handle="'.list-drag-handle'">
          <div v-for="list in card.lists" :key="list.id"
            class="flex items-start bg-white p-2 border border-gray-200 rounded" :data-id="list.id">
            <!-- 列表项拖拽句柄 -->
            <span class="list-drag-handle cursor-move text-gray-500 mr-2 mt-1">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
                <path d="M7 4h2v2H7V4zm0 4h2v2H7V8zm0 4h2v2H7v-2zm4-8h2v2h-2V4zm0 4h2v2h-2V8zm0 4h2v2h-2v-2z" />
              </svg>
            </span>
            <div class="flex-1">
              <!-- 列表项内容 -->
              <div class="text-left break-all break-words">
                {{ list.content }}
              </div>
            </div>
            <!-- 列表项的省略号菜单 -->
            <div class="relative flex-shrink-0 ml-2" @click.stop="toggleListMenu(list.id)">
              <svg class="w-5 h-5 cursor-pointer text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                <!-- 自定义垂直省略号 -->
                <circle cx="10" cy="5" r="1.2"></circle>
                <circle cx="10" cy="10" r="1.2"></circle>
                <circle cx="10" cy="15" r="1.2"></circle>
              </svg>
              <!-- 下拉菜单 -->
              <div v-if="activeListMenu === list.id"
                class="absolute right-0 mt-2 w-32 bg-white border border-gray-200 rounded-md shadow-lg z-10">
                <button @click="openModal('editList', card, list)"
                  class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                  编辑
                </button>
                <button @click="deleteList(list.id)"
                  class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                  删除
                </button>
              </div>
            </div>
          </div>
        </VueDraggableNext>

        <!-- 添加项目按钮 -->
        <button @click="openModal('addList', card)" class="mt-auto text-blue-500 text-sm hover:underline">
          + 添加项目
        </button>
      </div>
    </VueDraggableNext>

    <!-- 添加卡片按钮，跟随在卡片列表后面 -->
    <div
      class="add-card flex items-center justify-center w-64 h-32 bg-gray-100 border border-dashed border-gray-300 rounded-lg cursor-pointer hover:bg-gray-200"
      @click="openModal('addCard')">
      <button class="text-blue-500 text-lg">+ 添加卡片</button>
    </div>
  </div>

  <!-- 通用模态框 -->
  <div v-if="showModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20"
    @click.self="closeModal">
    <div class="bg-white rounded-lg p-6 w-80" @click.stop>
      <h2 class="text-xl mb-4">{{ modalTitle }}</h2>

      <!-- 根据 modalType 显示对应的表单项 -->
      <textarea v-model="modalData.content" :rows="modalRows" class="w-full p-2 border border-gray-300 rounded mb-4"
        :placeholder="modalPlaceholder"></textarea>

      <div class="flex justify-end">
        <button @click="closeModal" class="px-4 py-2 mr-2 bg-gray-300 rounded">取消</button>
        <button @click="confirmModalAction" class="px-4 py-2 bg-blue-500 text-white rounded">{{ modalButtonText
          }}</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue';
import { get, post } from '../utils'; // 请确保引入了正确的请求方法
import { VueDraggableNext } from 'vue-draggable-next'; // 引入 VueDraggableNext

// 初始化卡片数据
const cards = ref([]);

// 跟踪活动卡片菜单
const activeCardMenu = ref(null);

// 跟踪活动列表菜单
const activeListMenu = ref(null);

// 控制模态框的显示
const showModal = ref(false); // 控制通用模态框显示
const modalType = ref(''); // 通过这个字段区分不同操作：addCard、editCard、addList、editList
const modalTitle = ref(''); // 模态框标题
const modalPlaceholder = ref(''); // 模态框输入框的占位符
const modalButtonText = ref(''); // 模态框按钮文本
const modalRows = ref(3); // 控制textarea默认显示的行数

// 存储模态框中的数据
const modalData = reactive({
  content: '',
  card: null, // 存储当前操作的卡片或列表项信息
  list: null,
});

// 获取卡片和列表数据
const fetchCards = async () => {
  try {
    const data = await get('/api/board/get');
    cards.value = data.cards;
  } catch (err) {
    console.error('获取卡片失败:', err);
    alert(`获取卡片失败: ${err.message}`);
  }
};

// 在组件挂载时获取数据
onMounted(() => {
  fetchCards();
});

// 打开通用模态框
const openModal = (type, card = null, list = null) => {
  showModal.value = true;
  modalType.value = type;

  if (type === 'addCard') {
    modalTitle.value = '添加卡片';
    modalPlaceholder.value = '卡片标题';
    modalButtonText.value = '添加';
    modalRows.value = 1;
    modalData.content = '';
  } else if (type === 'editCard') {
    modalTitle.value = '编辑卡片';
    modalPlaceholder.value = '卡片标题';
    modalButtonText.value = '保存';
    modalRows.value = 1;
    modalData.content = card.title;
    modalData.card = card;
  } else if (type === 'addList') {
    modalTitle.value = '添加列表项';
    modalPlaceholder.value = '列表项内容';
    modalButtonText.value = '添加';
    modalRows.value = 3;
    modalData.content = '';
    modalData.card = card;
  } else if (type === 'editList') {
    modalTitle.value = '编辑列表项';
    modalPlaceholder.value = '列表项内容';
    modalButtonText.value = '保存';
    modalRows.value = 3;
    modalData.content = list.content;
    modalData.list = list;
    modalData.card = card;
  }
};

// 关闭模态框
const closeModal = () => {
  showModal.value = false;
  modalData.content = '';
};

// 确认操作
const confirmModalAction = async () => {
  try {
    if (modalType.value === 'addCard') {
      // 添加卡片逻辑
      if (modalData.content.trim() !== '') {
        const data = await post('/api/board/card/add', { title: modalData.content.trim() });
        // 添加新卡片到前端
        cards.value.push({
          id: data.card_id,
          title: modalData.content.trim(),
          lists: [],
          position: data.position || cards.value.length + 1,
        });
        closeModal();
      } else {
        alert('卡片标题不能为空');
      }
    } else if (modalType.value === 'editCard') {
      // 编辑卡片逻辑
      if (modalData.content.trim() !== '' && modalData.card && modalData.card.id) {
        await post('/api/board/card/edit', {
          card_id: modalData.card.id,
          title: modalData.content.trim(),
        });
        // 更新前端数据
        const card = cards.value.find((c) => c.id === modalData.card.id);
        if (card) {
          card.title = modalData.content.trim();
        }
        closeModal();
      } else {
        alert('卡片标题不能为空或卡片 ID 未正确设置');
      }
    } else if (modalType.value === 'addList') {
      // 添加列表项逻辑
      if (modalData.content.trim() !== '' && modalData.card && modalData.card.id) {
        const data = await post('/api/board/list/add', {
          card_id: modalData.card.id,
          content: modalData.content.trim(),
        });
        // 添加新列表项到前端
        const card = cards.value.find((c) => c.id === modalData.card.id);
        if (card) {
          card.lists.push({
            id: data.list_id,
            content: modalData.content.trim(),
            position: data.position || card.lists.length + 1,
          });
        }
        closeModal();
      } else {
        alert('列表项内容不能为空或卡片 ID 未正确设置');
      }
    } else if (modalType.value === 'editList') {
      // 编辑列表项逻辑
      if (modalData.content.trim() !== '' && modalData.list && modalData.list.id) {
        await post('/api/board/list/edit', {
          list_id: modalData.list.id,
          content: modalData.content.trim(),
        });
        // 更新前端数据
        const card = cards.value.find((c) => c.lists.some((l) => l.id === modalData.list.id));
        if (card) {
          const list = card.lists.find((l) => l.id === modalData.list.id);
          if (list) {
            list.content = modalData.content.trim();
          }
        }
        closeModal();
      } else {
        alert('列表项内容不能为空或列表 ID 未正确设置');
      }
    }
  } catch (err) {
    console.error('操作失败:', err);
    alert(`操作失败: ${err.message}`);
  }
};

// 拖拽卡片结束时的处理函数
const onCardDragEnd = async (event) => {
  const movedElementId = event.item.dataset.id; // 被拖拽的卡片 ID
  const newIndex = event.newIndex; // 新的索引位置
  const updatedCards = cards.value; // 更新后的卡片数组
  let newPosition;

  if (updatedCards.length === 1) {
    newPosition = 1;
  } else if (newIndex === 0) {
    newPosition = updatedCards[1].position - 1;
  } else if (newIndex === updatedCards.length - 1) {
    newPosition = updatedCards[updatedCards.length - 2].position + 1;
  } else {
    const prevPosition = updatedCards[newIndex - 1].position;
    const nextPosition = updatedCards[newIndex + 1].position;
    newPosition = prevPosition + (nextPosition - prevPosition) / 2;
  }

  try {
    await post('/api/board/card/position', { card_id: movedElementId, position: newPosition });
    await fetchCards();
  } catch (err) {
    console.error('更新卡片位置失败:', err);
    alert(`更新卡片位置失败: ${err.message}`);
  }
};

// 拖拽列表项结束时的处理函数
const onListDragEnd = async (event, card) => {
  const movedElementId = event.item.dataset.id;
  const newIndex = event.newIndex;
  const updatedLists = card.lists;
  let newPosition;

  if (updatedLists.length === 1) {
    newPosition = 1;
  } else if (newIndex === 0) {
    newPosition = updatedLists[1].position - 1;
  } else if (newIndex === updatedLists.length - 1) {
    newPosition = updatedLists[updatedLists.length - 2].position + 1;
  } else {
    const prevPosition = updatedLists[newIndex - 1].position;
    const nextPosition = updatedLists[newIndex + 1].position;
    newPosition = prevPosition + (nextPosition - prevPosition) / 2;
  }

  try {
    await post('/api/board/list/position', { list_id: movedElementId, position: newPosition });
    await fetchCards();
  } catch (err) {
    console.error('更新列表项位置失败:', err);
    alert(`更新列表项位置失败: ${err.message}`);
  }
};

// 删除卡片
const deleteCard = async (card_id) => {
  if (confirm('确定要删除此卡片吗？')) {
    try {
      await post('/api/board/card/delete', { card_id });
      cards.value = cards.value.filter((card) => card.id !== card_id);
    } catch (err) {
      console.error('删除卡片失败:', err);
      alert(`删除卡片失败: ${err.message}`);
    }
  }
};

// 删除列表项
const deleteList = async (list_id) => {
  if (confirm('确定要删除此列表项吗？')) {
    try {
      await post('/api/board/list/delete', { list_id });
      for (const card of cards.value) {
        card.lists = card.lists.filter((list) => list.id !== list_id);
      }
    } catch (err) {
      console.error('删除列表项失败:', err);
      alert(`删除列表项失败: ${err.message}`);
    }
  }
};

// 切换卡片菜单的显示
const toggleCardMenu = (card_id) => {
  if (activeCardMenu.value === card_id) {
    activeCardMenu.value = null;
  } else {
    activeCardMenu.value = card_id;
    activeListMenu.value = null;
  }
};

// 切换列表项菜单的显示
const toggleListMenu = (list_id) => {
  if (activeListMenu.value === list_id) {
    activeListMenu.value = null;
  } else {
    activeListMenu.value = list_id;
    activeCardMenu.value = null;
  }
};
</script>
