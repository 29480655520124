// src/utils/index.js

export async function request(url, method = 'GET', data = null, auth = true) {
    const headers = {
      'Content-Type': 'application/json',
    };
  
    // 如果需要认证，并且存在 token，则将其添加到请求头中
    if (auth && localStorage.getItem('token')) {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    }
  
    const options = {
      method,
      headers,
    };
  
    if (data) {
      options.body = JSON.stringify(data);
    }
  
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Request failed', error);
      throw error;
    }
  }
  
  // 简化的 GET 请求封装
  export function get(url, auth = true) {
    return request(url, 'GET', null, auth);
  }
  
  // 简化的 POST 请求封装
  export function post(url, data, auth = true) {
    return request(url, 'POST', data, auth);
  }
  