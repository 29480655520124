<template>
    <div class="p-4">
      <!-- 新增笔记输入框 -->
      <div class="mb-4">
        <textarea
          v-model="newNote"
          placeholder="请输入笔记内容..."
          class="w-full p-2 border border-gray-300 rounded"
        />
        <button @click="saveNote" class="mt-2 p-2 bg-blue-500 text-white rounded">
          保存笔记
        </button>
      </div>
  
      <!-- 搜索框 -->
      <div class="mb-4">
        <input
          v-model="searchTerm"
          type="text"
          placeholder="搜索笔记..."
          class="w-full p-2 border border-gray-300 rounded"
        />
        <button @click="searchNotes(1)" class="mt-2 p-2 bg-blue-500 text-white rounded">
          搜索
        </button>
      </div>
  
      <!-- 笔记列表 -->
      <div>
        <p v-if="loading">加载中...</p>
        <ul v-else-if="notes.length > 0">
          <li v-for="note in notes" :key="note.id" class="mb-2">
            <div class="font-semibold">
              <router-link :to="`/note/${note.id}`">
                {{ note.content.length > 100 ? note.content.substring(0, 100) + '...' : note.content }}
              </router-link>
            </div>
            <p>{{ new Date(note.created_at).toLocaleString() }}</p>
          </li>
        </ul>
        <p v-else>没有找到笔记。</p>
      </div>
  
      <!-- 分页按钮 -->
      <div class="mt-4">
        <button
          @click="handlePageChange(currentPage - 1)"
          :disabled="currentPage === 1"
          class="p-2 bg-gray-300 text-black rounded"
        >
          上一页
        </button>
        <span class="mx-4">第 {{ currentPage }} 页 / 共 {{ totalPages }} 页</span>
        <button
          @click="handlePageChange(currentPage + 1)"
          :disabled="currentPage === totalPages"
          class="p-2 bg-gray-300 text-black rounded"
        >
          下一页
        </button>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import { get, post } from '../utils';
  
  const router = useRouter();
  
  const searchTerm = ref('');
  const notes = ref([]);
  const loading = ref(false);
  const newNote = ref('');
  const currentPage = ref(1);
  const totalPages = ref(1);
  const pageSize = ref(10);
  
  // 检查用户是否已登录
  onMounted(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      router.push('/login');
    }
  });
  
  // 加载笔记列表
  const loadNotes = async (page = 1) => {
    loading.value = true;
    try {
      const data = await get(`/api/note/list?page=${page}&pageSize=${pageSize.value}`);
      notes.value = data;
      const totalNotes = 100; // 假设后端会返回总笔记数量
      totalPages.value = Math.ceil(totalNotes / pageSize.value);
    } catch (error) {
      console.error('Failed to load notes:', error);
    } finally {
      loading.value = false;
    }
  };
  
  // 搜索笔记
  const searchNotes = async (page = 1) => {
    loading.value = true;
    try {
      const data = await get(`/api/note/search?page=${page}&pageSize=${pageSize.value}&search=${encodeURIComponent(searchTerm.value)}`);
      notes.value = data;
      const totalNotes = 100; // 假设后端会返回总笔记数量
      totalPages.value = Math.ceil(totalNotes / pageSize.value);
    } catch (error) {
      console.error('Failed to search notes:', error);
    } finally {
      loading.value = false;
    }
  };
  
  // 保存笔记
  const saveNote = async () => {
    if (!newNote.value) return;
    loading.value = true;
    try {
      await post('/api/note/add', { note: newNote.value });
      newNote.value = '';
      loadNotes();
    } catch (error) {
      console.error('Failed to save note:', error);
    } finally {
      loading.value = false;
    }
  };
  
  // 处理分页
  const handlePageChange = (newPage) => {
    currentPage.value = newPage;
    if (searchTerm.value) {
      searchNotes(newPage);
    } else {
      loadNotes(newPage);
    }
  };
  
  // 监听当前页面变化
  watch(currentPage, () => {
    loadNotes(currentPage.value);
  });
  
  // 初始加载笔记列表
  onMounted(() => {
    loadNotes(currentPage.value);
  });
  </script>